import React from "react";
import { useState } from 'react'
import './StartingButtons.css'
import LandingTree from "./LandingTree";
import { useNavigate } from 'react-router-dom';

function StartingButtons(){
    const navigate = useNavigate()
    const navToNodeEnroll = () => {
        navigate('/nodeEnroll')
    }

    const onNewNodeClick = () =>{
        navToNodeEnroll()
    }
    const onNewProjectClick = () =>{

    }


    return(
        <>
            <div>
                <input 
                    onClick = {onNewNodeClick}
                    class ="create-buttons"
                    type="button"      
                    value="Create New Node"
                /> <br></br>
                <input
                    onClick={onNewProjectClick}
                    class="create-buttons"
                    type="button"
                    value="Create New Project"
                />
            </div>
        </>
    )
}
export default StartingButtons