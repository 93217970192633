import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './Login';
import RegistrationLogin from './RegistrationLogin';
import NodeEnroll from './NodeEnroll';
import DashboardLanding from './Dashboard/Landing';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/register" element={<RegistrationLogin />} />
        <Route path="/nodeEnroll" element={<NodeEnroll />} />
        <Route path="/Dashboard/Landing" element={<DashboardLanding />} />
      </Routes>
    </Router>
  );
}

export default App;