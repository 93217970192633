import React from "react";
import { PieChart } from '@mui/x-charts/PieChart';
import { useState } from "react";

function Pie(){
  const[nodeAValue, setNodeA] = useState(1);
  const[nodBAValue, setNodeB] = useState(1);
  const[nodeCValue, setNodeC] = useState(1);

  return (
    <PieChart
      series={[
        {
          data: [
            { id: 'nodeA', value: nodeAValue, label: 'Node A', color: 'pink' },
            { id: 'nodeB', value: nodBAValue, label: 'Node B', color: 'orange'},
            { id: 'nodeC', value: nodeCValue, label: 'Node C', color: 'black' },
          ],
          innerRadius: 60,
        },
      ]}
      width={400}
      height={200}
    />
  )
}
export default Pie