import React, { useState } from 'react';
import './LandingTree.css'

let allNodes = new Array();
let allProjects = new Array();

const addNodes = async () => {
  const t = localStorage.getItem("sessionToken");
  const level = 2;
  const headId = '';
  const url = new URL('/api/getNodeHierarchy', window.location.origin);
  url.searchParams.append('token', t);
  url.searchParams.append('levels', level);
  url.searchParams.append('headID', headId);

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    });
    const data = await response.json();
    console.log(data);

    const newNode = [{
      type: data.type,
      id: data.id,
      name: data.name,
      root: "rootNode",
      children: data.children,
    }];

    addChild(newNode);
  } catch (error) {
    console.error("Error fetching hierarchy:", error);
    console.log("Cannot get hierarchy");
  }
};

const NodeRootData = [
  {
    type: "dir",
    id: -1,
    name: "Nodes",
    root: "node",
    children: []
  }
]
const ProjectRootData = [
  {
    type: "dir",
    id: -2,
    name: "Projects",
    root: "project",
    children: []
  }
]

const Tree = ({ data }) => {
  /*
  let id = -99
  let root = ""
  {data.map((m) => (
    id = m.id
  ))}
  {data.map((m) => (
    root = m.root
  ))}
  if(allNodes.length == 0 && root == "node")
    allNodes.push(data)

  for(let i = 0; i < allNodes.length; i++){
    let arrId = -1
    let arrRoot = ""
    {allNodes[i].map((m) => (
      arrId = m.id
    ))}
    {data.map((m) => (
      root = m.root
    ))}
    if(arrId != id && root == arrRoot){
      allNodes.push(data)
    }
  }

  if(allProjects.length == 0 && root == "project")
    allProjects.push(data)

  for(let i = 0; i < allProjects.length; i++){
    let arrId = -99
    let arrRoot = ""
    {allProjects[i].map((m) => (
      arrId = m.id
    ))}
    {data.map((m) => (
      root = m.root
    ))}
    if(arrId != id && root == arrRoot){
      allProjects.push(data)
    }
  }
  */
  let root = ""
  {data.map((m) => (
    root = m.root
  ))}
  if(root == "node")
    allNodes.push(data)
  if(root == "project")
    allProjects.push(data)

  return (
    <ul>
      {data.map((node) => (
        <TreeNode key={node.id} node={node}/>
      ))}
    </ul>
  );
};

const TreeNode = ({ node }) => {
  let currId = 0
  let currType = ""
  let currName = ""
  let currChildren = []
  if(node.map){
    {node.map((m) => (
      currId = m.id
    ))}
    {node.map((m) => (
      currType = m.type
    ))}
    {node.map((m) => (
      currName = m.name
    ))}
    {node.map((m) => (
      currChildren = m.children
    ))}
  }
  else{
    currId = node.id
    currType = node.type
    currName = node.name
    currChildren = node.children
  }
 
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <li>
        {currType === "dir" && (
          <button class="tree-item" onClick={ev=>setIsOpen(!isOpen)}>
            {isOpen ? "v" : ">"} {currName}
          </button>
        )}
        {currType === "node" && <span>{currName}</span>}
        {isOpen && currChildren && currChildren.length > 0 && (
          <ul>
            {currChildren.map((child) => (
              <TreeNode key={child.id} node={child}/>
            ))}
          </ul>
        )}
      </li>
    </>
  )
}


const addChild = (childObj) =>{
  let parent = getParent(childObj)
  let kids = []
  {parent.map((m) => (
    kids = m.children
  ))}
  kids.push(childObj)
}

const getParent = (objToAdd) =>{
  let objRoot = -99
 {objToAdd.map((m) => (
    objRoot = m.root
  ))}
  // finding if parent exist and then adds to all nodes and returns parent node
  for(let i = 0; i < allNodes.length; i++){
    let curentId = -1
    {allNodes[i].map((m) => (
      curentId = m.id
    ))}
    if(objRoot == "rootNode" && curentId == -1){
      allNodes.push(objToAdd)
      return allNodes[i]
    }
    if(curentId == objRoot){
      allNodes.push(objToAdd)
      return allNodes[i]
    }
  }
  // finding if parent exist and then adds to all projects and returns parent projcet
  for(let i = 0; i < allProjects.length; i++){
    let curentId = -1
    {allProjects[i].map((m) => (
      curentId = m.id
    ))}
    if(objRoot == "rootProject" && curentId == -2){
      allProjects.push(objToAdd)
      return allProjects[i]
    }
    if(curentId == objRoot){
      allProjects.push(objToAdd)
      return allProjects[i]
    }
  }
  throw ("can't find parent") 
}

function printAllNodes(){
  console.log(allNodes)
}
function LandingTree(){
  const tree1 = <Tree data={NodeRootData}></Tree>;
  const tree2 = <Tree data={ProjectRootData}></Tree>;
  addNodes()
  return (
    <>
      <div className='tree-container'>
        {tree1}
        {tree2}    
      </div>
    </>
  )
}
export default LandingTree