import React from "react";
import { useState } from 'react'
import './LandingHead.css'

function LandingHead(){
    const[searchInput, setSearchInput] = useState("");
    return(
        <>
            <img src="/src/assets/logoPlaceholder.jpeg" alt="Company Logo" height="100px"/>
            <input
                type="text"
                placeholder="Search here"
                onChange={ev=>setSearchInput(ev.target.value)}
                value={searchInput} 
                class="search-input"
            />
        </>
    )
}
export default LandingHead