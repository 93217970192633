import React from "react";
import { useState } from 'react'
import './Schedule.css'

function Schedule(){

    return(
        <>
            <div className="schedule-box">
                <div className="day1"><p>Sunday</p></div>
                <div className="day2"><p>Monday</p></div>
                <div className="day3"><p>Tuesday</p></div>
                <div className="day4"><p>Wednesday</p></div>
                <div className="day5"><p>Thuresday</p></div>
                <div className="day6"><p>Friday</p></div>
                <div className="day7"><p>Satureday</p></div>
            </div>
        </>
    )
}
export default Schedule